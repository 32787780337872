<template>
  <div>
    <el-carousel
      :interval="5000"
      arrow="always"
      style="width:100%;"
      height="700px"
    >
      <el-carousel-item
        v-for="(item, index) in bannerList"
        :key="index"
        :class="'carouselSty' + index"
      >
        <!-- <h3>{{ item }}</h3> -->
        <router-link :to="{ path: item.link }">
          <el-image
            :src="item.url"
            fit="contain"
            style="width:100%; height: 700px;"
          ></el-image>
        </router-link>
      </el-carousel-item>
    </el-carousel>
    <div style="margin-top:50px;margin-bottom:50px">
      <el-image
        :src="jjfatitleUrl"
        fit="contain"
        style="width:150px; height:50px"
      ></el-image>
      <el-row :gutter="20" style="margin-top:50px">
        <router-link :to="{ path: '/components/web/solutions/case01' }">
          <el-col :span="6"
            ><div class="grid-content bg-purple">
              <el-image
                :src="icon1"
                fit="contain"
                style="width:100px;
            height:100px"
              ></el-image>
              <div class="jjfaTitle">{{ $t('Nav.qiyezonghehuaguanli') }}</div>
              <div class="jjfamiaoshu">{{ $t('Nav.indexText01') }}</div>
            </div></el-col
          >
        </router-link>
        <router-link :to="{ path: '/components/web/solutions/case02' }">
          <el-col :span="6"
            ><div class="grid-content bg-purple">
              <el-image
                :src="icon2"
                fit="contain"
                style="width:100px;
            height:100px"
              ></el-image>
              <div class="jjfaTitle">{{ $t('Nav.nengyuanshihuaguanli') }}</div>
              <div class="jjfamiaoshu">
                {{ $t('Nav.indexText02') }}
              </div>
            </div></el-col
          >
        </router-link>
        <router-link :to="{ path: '/components/web/solutions/case03' }">
          <el-col :span="6"
            ><div class="grid-content bg-purple">
              <el-image
                :src="icon3"
                fit="contain"
                style="width:100px;
            height:100px"
              ></el-image>
              <div class="jjfaTitle">{{ $t('Nav.guojiyunying') }}</div>
              <div class="jjfamiaoshu">{{ $t('Nav.indexText03') }}</div>
            </div></el-col
          >
        </router-link>
        <router-link :to="{ path: '/components/web/solutions/case04' }">
          <el-col :span="6"
            ><div class="grid-content bg-purple">
              <el-image
                :src="icon4"
                fit="contain"
                style="width:100px;
            height:100px"
              ></el-image>
              <div class="jjfaTitle">{{ $t('Nav.dashuju') }}</div>
              <div class="jjfamiaoshu">{{ $t('Nav.indexText04') }}</div>
            </div></el-col
          >
        </router-link>
      </el-row>
    </div>

    <div style="margin-top:50px;">
      <el-image
        :src="ict"
        fit="contain"
        style="width:320px; height:50px"
      ></el-image>
      <div style="height:50px"></div>
      <div style="width:1200px;margin:0 auto">
        <el-row :gutter="20">
          <router-link :to="{ path: '/components/web/itc/case01' }">
            <el-col :span="8"
              ><div
                class="grid-content2"
                @mouseenter="showTextFun(1)"
                @mouseleave="hideTextFun"
              >
                <el-image
                  :src="img1"
                  fit="contain"
                  style="width:100%;"
                ></el-image>

                <transition name="el-zoom-in-center">
                  <div
                    v-show="showText"
                    style="position: relative; position: absolute;top:0;left:0; width:100%; height:100%; background:#182AC3; opacity:0.7"
                  >
                    <div class="posStyl">
                      <div
                        style="display:inline-block; border-top:1px solid #ffffff; width:50px;"
                      ></div>
                      <div class="imgText">{{ $t('Nav.quanfangweiitc') }}</div>
                      <div class="imgText">
                        {{ $t('Nav.indexImgText01') }}
                      </div>
                      <div class="imgText">
                        {{ $t('Nav.indexImgText02') }}
                      </div>
                      <div
                        style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:10px"
                      ></div>
                    </div>
                  </div>
                </transition></div
            ></el-col>
          </router-link>
          <router-link :to="{ path: '/components/web/itc/case02' }">
            <el-col :span="8"
              ><div
                class="grid-content2"
                @mouseenter="showTextFun(2)"
                @mouseleave="hideTextFun"
              >
                <el-image
                  :src="img2"
                  fit="contain"
                  style="width:100%;"
                ></el-image>

                <transition name="el-zoom-in-center">
                  <div
                    v-show="showText2"
                    style="position: relative; position: absolute;top:0;left:0; width:100%;height:100%;   background:#182AC3; opacity:0.7"
                  >
                    <div class="posStyl">
                      <div
                        style="display:inline-block; border-top:1px solid #ffffff; width:50px;"
                      ></div>
                      <div class="imgText">{{ $t('Nav.itc') }}</div>
                      <div class="imgText">{{ $t('Nav.indexImgText03') }}</div>
                      <div
                        style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:10px"
                      ></div>
                    </div>
                  </div>
                </transition></div
            ></el-col>
          </router-link>
          <router-link :to="{ path: '/components/web/itc/case03' }">
            <el-col :span="8"
              ><div
                class="grid-content2"
                @mouseenter="showTextFun(3)"
                @mouseleave="hideTextFun"
              >
                <el-image
                  :src="img3"
                  fit="contain"
                  style="width:100%;"
                ></el-image>

                <transition name="el-zoom-in-center">
                  <div
                    v-show="showText3"
                    style="position: relative; position: absolute;top:0;left:0; width:100%; height:100%;  background:#182AC3; opacity:0.7"
                  >
                    <div class="posStyl">
                      <div
                        style="display:inline-block; border-top:1px solid #ffffff; width:50px;"
                      ></div>
                      <div class="imgText">{{ $t('Nav.anlifenxiang') }}</div>
                      <div class="imgText">
                        {{ $t('Nav.indexImgText04') }}
                      </div>
                      <div class="imgText">
                        {{ $t('Nav.indexImgText05') }}
                      </div>
                      <div
                        style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:10px"
                      ></div>
                    </div>
                  </div>
                </transition></div
            ></el-col>
          </router-link>
        </el-row>
      </div>

      <div style="height:50px"></div>
      <el-image :src="zizhiUrl" fit="contain" style="width:100%;"></el-image>
      <div style="height:80px"></div>
      <div class="hzgs">{{ $t('Nav.hezuogongsi') }}</div>
      <el-divider></el-divider>
      <div style="height:30px"></div>
      <el-row :gutter="24">
        <el-col :span="4">
          <el-image
            class="cake"
            :src="hezuo1"
            fit="contain"
            style="width:100%; height:50px"
          ></el-image
        ></el-col>
        <el-col :span="4">
          <el-image
            :src="hezuo2"
            class="cake"
            fit="contain"
            style="width:100%; height:50px"
          ></el-image>
        </el-col>
        <el-col :span="4">
          <el-image
            :src="hezuo3"
            class="cake"
            fit="contain"
            style="width:100%; height:50px"
          ></el-image>
        </el-col>
        <el-col :span="4">
          <el-image
            :src="hezuo4"
            class="cake"
            fit="contain"
            style="width:100%; height:50px"
          ></el-image>
        </el-col>
        <!-- <el-col :span="2">
          <el-image
            :src="hezuo5"
            class="cake"
            fit="contain"
            style="width:100%; height:50px"
          ></el-image>
        </el-col> -->
        <el-col :span="4">
          <el-image
            :src="hezuo6"
            class="cake"
            fit="contain"
            style="width:100%; height:50px"
          ></el-image>
        </el-col>
        <el-col :span="4">
          <el-image
            :src="hezuo7"
            fit="contain"
            class="cake"
            style="width:100%; height:50px"
          ></el-image>
        </el-col>
      </el-row>
      <div style="height:100px"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  components: {
    // HelloWorld
  },
  data() {
    return {
      carouselSty: '',
      showText: false,
      showText2: false,
      showText3: false,
      bannerList: [
        { url: require('../../assets/index/01-banner1.png'), link: '' },
        {
          url: require('../../assets/index/01-banner2.png'),
          link: '/components/web/aboutus/index',
        },
        {
          url: require('../../assets/index/01-banner3.png'),
          link: '/components/web/solutions/index',
        },
        {
          url: require('../../assets/index/01-banner4.png'),
          link: '/components/web/itc/index',
        },
        {
          url: require('../../assets/index/01-banner5.png'),
          link: '/components/web/joinus/index',
        },
      ],
      zizhiUrl: require('../../assets/index/banner02.jpg'),
      jjfatitleUrl: require('../../assets/index/jjfatitle.png'),
      icon1: require('../../assets/index/icon1.png'),
      icon2: require('../../assets/index/icon2.png'),
      icon3: require('../../assets/index/icon3.png'),
      icon4: require('../../assets/index/icon4.png'),
      ict: require('../../assets/index/ICT.jpg'),
      img1: require('../../assets/index/img1.jpg'),
      img2: require('../../assets/index/img2.jpg'),
      img3: require('../../assets/index/img3.jpg'),
      hezuo1: require('../../assets/index/hezuo1.png'),
      hezuo2: require('../../assets/index/hezuo2.png'),
      hezuo3: require('../../assets/index/hezuo3.png'),
      hezuo4: require('../../assets/index/hezuo4.png'),
      hezuo5: require('../../assets/index/hezuo5.png'),
      hezuo6: require('../../assets/index/hezuo6.png'),
      hezuo7: require('../../assets/index/bp.jpg'),
    }
  },
  methods: {
    showTextFun(num) {
      if (num == 1) {
        this.showText = true
        this.showText2 = false
        this.showText3 = false
      } else if (num == 2) {
        this.showText = false
        this.showText2 = true
        this.showText3 = false
      } else {
        this.showText = false
        this.showText2 = false
        this.showText3 = true
      }
    },
    hideTextFun() {
      this.showText = false
      this.showText2 = false
      this.showText3 = false
    },
  },
}
</script>

<style>
.carouselSty0 {
  background: #010749;
}
.carouselSty1 {
  background: #010b30;
}
.carouselSty2 {
  background: #1018af;
}
.carouselSty3 {
  background: #03063c;
}
.carouselSty4 {
  background: #3e06a5;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}
.jjfaTitle {
  height: 20px;
  font-size: 21px;
  font-family: Noto Sans S Chinese;
  font-weight: 300;
  color: #2c3dd6;
  line-height: 20px;
  margin: 5px 0;
}
.jjfamiaoshu {
  height: 16px;
  font-size: 16px;
  font-family: Noto Sans S Chinese;
  font-weight: 300;
  color: #666666;
  line-height: 16px;
  margin-top: 32px;
}
.grid-content {
  text-align: center;
  height: 250px;
  line-height: 50px;
  padding-top: 50px;
}
.grid-content:hover {
  cursor: pointer;
  background: #f6f8f9;
  height: 250px;
}
.grid-content2 {
  position: relative;
  cursor: pointer;
}
.imgText {
  font-size: 14px;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #ffffff;
  margin: 10px;
}
.hzgs {
  height: 23px;
  font-size: 24px;
  font-family: Noto Sans S Chinese;
  font-weight: bold;
  color: #475aff;
  line-height: 37px;
}

.cake:hover {
  animation: move 2s 0s infinite;
  -webkit-animation: move 2s 0s infinite;
  transform-origin: bottom;
  -webkit-transform-origin: bottom;
}

@keyframes move {
  0%,
  65% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  70% {
    -webkit-transform: rotate(6deg);
    transform: rotate(6deg);
  }
  75% {
    -webkit-transform: rotate(-6deg);
    transform: rotate(-6deg);
  }
  80% {
    -webkit-transform: rotate(6deg);
    transform: rotate(6deg);
  }
  85% {
    -webkit-transform: rotate(-6deg);
    transform: rotate(-6deg);
  }
  90% {
    -webkit-transform: rotate(6deg);
    transform: rotate(6deg);
  }
  95% {
    -webkit-transform: rotate(-6deg);
    transform: rotate(-6deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@-webkit-keyframes move {
  0%,
  65% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  70% {
    -webkit-transform: rotate(6deg);
    transform: rotate(6deg);
  }
  75% {
    -webkit-transform: rotate(-6deg);
    transform: rotate(-6deg);
  }
  80% {
    -webkit-transform: rotate(6deg);
    transform: rotate(6deg);
  }
  85% {
    -webkit-transform: rotate(-6deg);
    transform: rotate(-6deg);
  }
  90% {
    -webkit-transform: rotate(6deg);
    transform: rotate(6deg);
  }
  95% {
    -webkit-transform: rotate(-6deg);
    transform: rotate(-6deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
.posStyl {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
